<template>
  <div>
    <b-form-group :label="label" :state="state">
      <template v-if="label" v-slot:label>
        {{ label }}
        <span v-if="required" class="text-danger"> (*) </span>
      </template>
      <v-select
        v-model="type"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="types"
        :placeholder="placeholder"
        @input="valueChanged"
        @open="onOpen"
      >
        <template
          v-if="enableAdd === true && $can('create', 'setting')"
          #list-header
        >
          <li
            v-b-modal="`modal-general-data-${generalDataType}`"
            class="list-header d-flex align-items-center my-50"
          >
            <feather-icon icon="PlusIcon" size="16" />
            <span class="align-middle ml-25">{{ t("Thêm mới") }}</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select
      >
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <!-- modal -->
    <general-data-modal
      v-if="enableAdd === true"
      :item="{}"
      :label="label"
      :type="generalDataType"
      @refetch-data="fetchGeneralDatas"
      @on-item-created="onItemCreated"
    />
  </div>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import GeneralDataModal from "../settings/general-data/modal/GeneralDataModal.vue";

export default {
  components: {
    BFormGroup,
    vSelect,
    GeneralDataModal,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    generalDataType: {
      type: String,
      required: true,
      default: "paymentPeriod",
    },
    placeholder: {
      type: String,
      required: true,
      default: "Lần TT",
    },
  },
  data() {
    return {
      type: this.defaultValue,
      types: [],
    };
  },
  created() {
    this.fetchGeneralDatas();
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    valueChanged() {
      this.$emit("input", this.type);
    },
    fetchGeneralDatas() {
      useJwt
        .getGeneralDatas({ filter: { type: this.generalDataType } })
        .then((res) => {
          this.types = res.data.items;
        });
    },
    onOpen() {
      if (this.types.length === 0) {
        this.fetchGeneralDatas();
      }
    },
    onItemCreated(newItem) {
      this.type = newItem;
      this.valueChanged();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
.list-header {
  padding: $options-padding-y $options-padding-x;
  color: $secondary;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>
